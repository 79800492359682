<template>
  <router-view />
</template>
<script></script>
<style lang="scss">
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

:root {
  --primary-colour: #fff;
  --secondary-colour: #d5d8e1;
  --action-colour: #3bafda;
  --text-colour: #2e2f35;
  --secondary-text-colour: #fff;
  --warning-colour: #f44336;
  --banner-colour: #2e2f35;
  --border-colour: #ecf2f8;
  --hover-colour: #ededed;
  --box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.5);
  --centrapay-orange: #ff5c02;
}

:root.dark-theme {
  --primary-colour: #2e2f35;
  --secondary-colour: #444548;
  --action-colour: #3bafda;
  --text-colour: #d8d5d5;
  --secondary-text-colour: #2e2f35;
  --warning-colour: #f44336;
  --banner-colour: #2e2f35;
  --border-colour: #2e2f35;
  --hover-colour: #2e2f35;
  --box-shadow: 0 0 0.5rem 0 rgba(168, 158, 158, 0.5);
  --centrapay-orange: #ff5c02;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-colour: #2e2f35;
    --secondary-colour: #444548;
    --action-colour: #3bafda;
    --text-colour: #d8d5d5;
    --secondary-text-colour: #2e2f35;
    --warning-colour: #f44336;
    --banner-colour: #2e2f35;
    --border-colour: #2e2f35;
    --hover-colour: #2e2f35;
    --box-shadow: 0 0 0.5rem 0 rgba(168, 158, 158, 0.5);
    --centrapay-orange: #ff5c02;
  }

  :root.light-theme {
    --primary-colour: #fff;
    --secondary-colour: #d5d8e1;
    --action-colour: #3bafda;
    --text-colour: #2e2f35;
    --secondary-text-colour: #fff;
    --warning-colour: #f44336;
    --banner-colour: #2e2f35;
    --border-colour: #ecf2f8;
    --hover-colour: #ededed;
    --box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.5);
    --centrapay-orange: #ff5c02;
  }
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Lato";
  color: var(--text-colour);
  background-color: var(--primary-colour);
}

form {
  font-size: 1em;
  label {
    display: block;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  input[type="text"],
  input[type="file"],
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  textarea {
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: 1px solid #ccc;
    margin-bottom: 0.5rem;
    width: calc(100% - 2rem);
  }
}

button {
  padding: 1rem;
  border-radius: 0.3rem;
  border: none;
  background-color: var(--action-colour);
  box-shadow: var(--box-shadow);
  font-size: 1rem;
  color: var(--secondary-text-colour);
  margin-left: 1rem;
  display: inline-block !important;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

button.primary {
  background-color: var(--action-colour) !important;
  color: var(--primary-colour) !important;
}

button.persist {
  background-color: #65da3b !important;
}

button.warning {
  background-color: #ff9700 !important;
}

button.danger {
  background-color: #ff003b !important;
}

button.action {
  padding: 0.7rem;
  border-radius: 0.3rem;
  // border: 1px solid #ccc;
  background-color: var(--primary-colour);
  color: var(--text-colour);
  margin: 0.5rem;
  width: 200px;
  display: inline-block !important;
}

.toggle-handle {
  height: 48px !important;
  width: 48px !important;
}

.ql-container {
  background-color: var(--primary-colour);
}
.ql-toolbar {
  background-color: var(--secondary-text-colour);
}
</style>
