import App from "./App.vue";
import { createStore } from "vuex";
import cart from "./store/cart.js";
import token from "./store/token.js";
import { createApp } from "vue";
import { api } from "@/helpers/api";
import Notifications from "@kyvg/vue3-notification";
//import * as Sentry from "@sentry/vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEdit,
  faPlus,
  faMinus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faCircleMinus,
  faCirclePlus,
  faChevronDown,
  faChevronUp,
  faGripHorizontal,
  faGripLines,
  faCamera,
  faBackward,
  faCartShopping,
  faTag,
  faFileInvoiceDollar,  
  faUser,
  faUserAstronaut,
  faGift,
  faEnvelope,
  faArrowRight,
  faArrowLeft,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faPhone,
  faEdit,
  faPlus,
  faMinus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faCircleMinus,
  faCirclePlus,
  faChevronDown,
  faChevronUp,
  faGripHorizontal,
  faGripLines,
  faCamera,
  faBackward,
  faCartShopping,
  faTag,
  faFileInvoiceDollar,
  faUser,
  faUserAstronaut,
  faGift,
  faEnvelope,
  faArrowRight,
  faArrowLeft,
  faXmark
);

import { createRouter, createWebHistory } from "vue-router";
//import loggingPlugin from "@/plugins/loggingPlugin";
//import { createLogger } from 'vuex'

const Store = () => import("./components/Store/Store.vue");
const Cart = () => import("./components/Store/Cart.vue");
const PasswordReset = () => import("./components/Auth/PasswordReset.vue");

const store = createStore({
  modules: { token: token, cart: cart },
  //plugins: [createLogger()];
});

//const Cart = () => import('./components/Store/Cart.vue');

const router = new createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes: [
    { path: "/", name: "Home", component: Store, meta: { requiresAuth: true } },
    { path: "/cart", component: Cart, meta: { requiresAuth: true } },
    { path: "/store", component: Store, meta: { requiresAuth: false } },
    { path: "/store/:category/:product", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:id", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:id/checkout", component: Store, meta: { requiresAuth: false } },
    { path: "/web", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:category/:product", component: Store, meta: { requiresAuth: false } },
    { path: "/web/:id/:category/:product", component: Store, meta: { requiresAuth: false } },
    { path: "/reset/:token", name: "PasswordReset", component: PasswordReset, meta: { requiresAuth: false } },
  ],
});

export default store;

const isAuthenticated = async () => {
  const stations = JSON.parse(window.localStorage.getItem("stations"));
  let auth = false;

  if (stations && stations.length > 0) {
    auth = true;
  }
  return store.getters.token && auth ? true : false;
};

const isWebStore = async (id) => {
  await store.commit("setWebStoreMutation", true);
  await api.getSite(id);
};


router.beforeEach(async (to, from, next) => {
  let url = window.location.host.split(".");

  if (to.params.id) {
    await isWebStore(to.params.id);
    return next();
  } else if (url[1] == "web") {
    to.params.id = url[0];
    await isWebStore(url[0]);
    return next();
  } else {
    await store.commit("setWebStoreMutation", true);
    //api.loadTheme(JSON.parse(window.localStorage.getItem("theme")));
  }

  const authenticated = await isAuthenticated();
  if (to.meta.requiresAuth) {
    if (authenticated) return next();

    return next({ name: "Login" });
  }

  return next();
});

const app = createApp(App);

/***Sentry.init({
  app,
    dsn: "https://9b3a6f84bb430c86b56898fca7497078@o4505633539489792.ingest.sentry.io/4505633543880704",
    integrations: [
	new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});***/

app
  .use(Notifications)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
//app.use(loggingPlugin);
